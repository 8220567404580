<template>
  <div>
    <div id="step-3-content" class="container">
      <div class="row">
        <div class="row col-12 p-0 m-0 mt-4">
          <div class="col-12 text-left">
            <h5 class="m-0 mt-3 dark-blue-text font-weight-bold">
              お部屋の写真の詳細を選んでください
            </h5>
          </div>
        </div>
      </div>
      <b-form-checkbox-group>
        <div id="item-list">
          <div
            class="row photo-details-item"
            v-for="(el, i) in staging.items"
            :key="i"
          >
            <div
              class="col-12 col-md-12 col-lg-12 col-xl-4 position-relative p-0"
            >
              <div class="photo" style="padding:15px;">
                <img
                  :src="el.file_url"
                  @click="openModal(el.file_url)"
                  onerror="this.src='/img/image-error.png'"
                />
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-7">
              <div class="list">
                <div class="col-12 col-md-12 col-lg-12 col-xl-6 ">
                  <div class="d-flex mt-3">
                    <p class="m-0 font-weight-bold light-grey-text">
                      ITEM {{ i + 1 }}
                    </p>
                  </div>
                  <div class="d-flex mt-1">
                    <input
                      class="form-control shadow-1 cursor-default"
                      type="text"
                      style="height:40px; width:140px;"
                      :placeholder="el.filename"
                      readonly
                    />
                    <p class="mb-0 ml-3 grey-text" style="margin-top: 23px; font-size:15px;">
                      .{{ el.file_extension.toUpperCase() }}
                    </p>
                  </div>
                  <div class="mt-3">
                    <p>
                      <span class="mr-2 font-weight-bold dark-blue-text">
                        選択したデザインスタイル<br>
                      </span>
                      <span v-for="(style, i) in styleList" :key="i">
                        <span v-if="style.id == el.staging_request.furnishing_style_id" >{{ style.name }}</span>
                      </span>
                    </p>
                  </div>
                  <div class="mt-3">
                    <p class="m-0">
                      <span class="mr-2 font-weight-bold dark-blue-text">
                        お部屋の種類
                      </span>
                      <b-spinner
                        class="grey-text"
                        small
                        v-if="all_roomTypes.loader"
                      ></b-spinner>
                    </p>
                    <b-form-select
                      class="shadow-1 room-type-select"
                      style="height:40px; width:200px;"
                      v-on:change="(selected) => changeType(selected, el.id, i)"
                      v-model="roomType[i].value"
                      :options="
                        all_roomTypes.options.length > 0
                          ? all_roomTypes.options
                          : null
                      "
                    ></b-form-select>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-lg-12 layout">
                  <div class="mt-3">
                    <span class="mr-2 font-weight-bold dark-blue-text">
                      物件名/号室
                    </span>
                    <b-form-input
                      v-model="property_name[i].value"
                      class="form-input"
                      placeholder="ご自由に記入ください。"
                    ></b-form-input>
                  </div>
                  <div v-if="drawingCount[el.id] < 10">
                    <div class="d-flex">
                      <input
                        :id="'drawing-uploader_'+ el.id"
                        type="file"
                        class="form-control"
                        accept=".jpeg,.jpg,.png,.pdf"
                        v-on:change="validatePdfs(el.id, $event)"
                        multiple
                        hidden
                      />
                      <div class="img-upload-box">
                        <div class="main-container">
                          <button
                            class="btn btn-dt dark-blue-outline dark-blue-text"
                            style="width: 250px; height:40px; margin-left: -17px;"
                            v-waves.light
                            v-on:click="setAddPdf(drawingCount[el.id], el.id)"
                            :disabled="
                            drawingCount[el.id] > 10 ? true : false
                            "
                          >
                            <div class="drawing-area">
                              <font-awesome-icon class="icon" :icon="{ prefix: 'fas', iconName: 'file-arrow-up' }"/>
                              <span v-if="drawingCount[el.id] == 0" style="margin-left: 10px;" class="img-btn">参考図面をアップロード</span>
                              <span v-else class="img-btn">{{ drawingCount[el.id] }}件アップロード済み</span>
                            </div>
                            
                          </button>
                          <span @click="attention" class="mr-2 font-weight-bold dark-blue-text" style="margin-top:34px; margin-left:6px; font-size: 11px; cursor: pointer;" >※図面アップロード時の注意点はこちら</span>
                        </div>
                      </div>
                      <div class="text-right">
                          <button
                            class="btn btn-dt dark-blue-outline dark-blue-text"
                            style="width: 120px; margin-left: -17px;"
                            v-waves.light
                            :disabled="
                              drawingCount[el.id] > 0 ? false : true
                            "
                            v-on:click="delPdf(drawingCount[el.id], el.id)"
                          >
                            <div class="drawing-area" style="background:#055ab8; color:#fff">
                              <span class="img-btn">図面削除</span>
                            </div>
                          </button>
                      </div>

                    </div>
                  </div>
                  <div v-if="drawingCount[el.id] > 0">
                    <div class="d-flex">
                      <input
                        id="drawing-uploader"
                        type="file"
                        class="form-control"
                        accept=".jpeg,.jpg,.png,.pdf"
                        v-on:change="validatePdfs(el.id, $event)"
                        multiple
                        hidden
                      />
                      
                    </div>
                  </div>
                  <div class="col-12 col-md-12 col-lg-12 mt-0" style="margin-left:-15px;">
                    <div class="option-select mt-3">
                      <p class="m-0">
                        <span class="mr-5 font-weight-bold dark-blue-text">
                          追加オプション
                        </span>
                        <b-spinner
                          class="grey-text"
                          small
                          v-if="all_additionalOptions.loader"
                        ></b-spinner>
                      </p>
                      <div class="mt-1" v-if="all_additionalOptions.loader">
                        <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                        <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                        <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                        <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                      </div>
                      <b-form-group v-else>
                        <b-form-checkbox-group
                          v-model="additionalOptions[i].value"
                          name="additional-options"
                        >
                          <div class="item-font">
                            <div
                              class="item"
                              style="font-size:13px;"
                              v-for="(el, i) in all_additionalOptions.options"
                              :key="i"
                            >
                              <b-form-checkbox
                                class="grey-text step3"
                                :value="el.value"
                              >
                                {{ el.text }} - {{ formatPrice(el.price) }}
                              </b-form-checkbox>
                            </div>
                          </div>
                        </b-form-checkbox-group>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                  <p class="m-0">
                    <span class="mr-2 font-weight-bold dark-blue-text">
                      家具を選択
                    </span>
                    <b-spinner
                      class="grey-text"
                      small
                      v-if="furnitures[i].loader"
                    ></b-spinner>
                  </p>
                  <div class="row mt-1" v-if="furnitures[i].loader">
                    <div
                      class="col-12 col-md-6 col-lg-6 col-xl-6"
                      v-for="(el, i) in 6"
                      :key="i"
                    >
                      <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                    </div>
                  </div>
                  <b-form-group>
                    <b-form-checkbox-group
                      v-model="furnitures[i].value"
                      name="furnitures"
                    >
                      <div class="select-furniture">
                        <div
                          class="col-12 col-md-6 col-lg-6 col-xl-6"
                          style="font-size:13px;"
                          v-for="(el, i) in furnitures[i].options"
                          :key="i"
                          @click="checkOptional(el.is_optional, el.value)"
                        >
                          <b-form-checkbox
                            class="d-block grey-text step3"
                            :value="el.value"
                          >
                            {{ el.text }}
                          </b-form-checkbox>
                        </div>
                      </div>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
                <div class="col-12">
                  <span class="mr-2 font-weight-bold dark-blue-text">コメント</span>
                  <b-form-textarea
                    v-model="remarks[i].value"
                    class="form-area"
                    placeholder="以下の特殊な記号は使えません。 !@#$%^&*()[]"
                    rows="5"
                    max-rows="5"
                  ></b-form-textarea>
                </div>
                <div class="col-12">
                  <span class="mr-2 font-weight-bold dark-blue-text">デザインリストへの表示</span>
                  <div class="col-11" style="padding-bottom:10px;">
                    <b-form-group>
                      <b-form-checkbox-group>
                        <b-form-checkbox
                          class="d-block grey-text step3"
                          v-model="disp_list[i].value"
                          value="1"
                        >
                          <div class="detail-font staging-detail">デザインリストへの表示に同意する</div>
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </div>
                  <div class="col-12" v-show="disp_list[i].value == 1">
                    <div class="pinterest-category" style="padding-bottom: 5px;">
                      <div class="detail-font staging-detail">間取り</div>
                      <b-spinner
                        small
                        v-if="houseLayout.loader"
                      ></b-spinner>
                      <b-form-select
                        class="shadow-1"
                        v-model="selectHouseLayout[i].value"
                        :options="houseLayout.options"
                      ></b-form-select>
                    </div>
                    <div class="pinterest-category" style="padding-bottom: 5px;">
                      <div class="detail-font staging-detail">都道府県</div>
                      <b-spinner
                        small
                        v-if="prefectures.loader"
                      ></b-spinner>
                      <b-form-select
                        class="shadow-1"
                        v-model="selectPrefectures[i].value"
                        :options="prefectures.options"
                      ></b-form-select>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </b-form-checkbox-group>
      <b-alert
        class="m-0 mt-5 light-grey-outline grey-text"
        variant="warning"
        show
        v-if="formMessage !== null"
      >
        {{ formMessage }}
      </b-alert>
    </div>

    <b-modal
      id="step3-remove-photo"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="$bvModal.hide('step3-remove-photo')"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          この依頼を削除してもよろしいですか？
        </h5>
        <div class="row mt-5 pl-5 pr-5 pb-3">
          <div class="col-12 col-md-6 col-lg-6 col-xl-6">
            <button
              class="btn btn-ds light-grey white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="$bvModal.hide('step3-remove-photo')"
            >
              キャンセル
            </button>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-6">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="setRemovePhoto"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="step3-attention"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="$bvModal.hide('step3-attention')"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <img class="mt-4" src="/img/drawing.png" alt="ico-info" />
        <div class="row mt-5 pl-5 pr-5 pb-3">
          <div class="col-12 col-md-12 col-lg-12 col-xl-12">
            <button
              class="btn btn-ds dark-blue white-text "
              type="button"
              v-waves.light
              v-on:click="$bvModal.hide('step3-attention')"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <image-modal @closeModal="closeModal()" v-if="isOpenModal">
      <img :src="modalImageUrl">
    </image-modal>
  </div>
</template>

<script>
// Import Services
import {
  cus_stagingService_GetRooms,
  cus_stagingService_GetAditionalOptions,
  cus_stagingService_GetAllFurnitures,
  cus_stagingService_GetHouseLayout,
  cus_stagingService_UpdateSRUpdateItems,
  cus_stagingService_GetAllSRItems,
  cus_stagingService_DeleteSRItems,
  cus_stagingService_DeleteDrawing,
  cus_stagingService_UpdateDrawing,
  cus_stagingService_GetOfficeFunishingStyle,
} from '../../services/customer/staging';

// Import Modal
import ImageModal from '../../components/customer/Image-Modal.vue';

// Import Vuex/Store
import { mapGetters } from 'vuex';

//import common
import { formatPrice } from '../../common/Utility';

import { globalService_GetAllPrefectures } from '../../services/global';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | New Request',
    };
  },

  data() {
    return {
      itemSelectStatus: false,
      roomType: [],
      furnitures: [],
      additionalOptions: [],
      itemsToBeDeleted: [],
      formMessage: null,
      all_roomTypes: {
        options: [],
        loader: true,
      },
      all_furnitures: {
        options: [],
        loader: true,
      },
      all_additionalOptions: {
        options: [],
        loader: true,
      },
      remarks: [],
      property_name: [],
      loaderItems: [],
      uploadCount: 0,
      drawingNumber: [],
      drawing: [],
      drawingFileName: [],
      isOpenModal: false,
      modalImageUrl: null,
      optionalValue: [],
      styleList: null,
      disp_list: [],
      drawingCount: [],
      prefectures: {
        options: [],
        loader: true,
      },
      houseLayout: {
        options: [],
        loader: true,
      },
      selectPrefectures: [],
      selectHouseLayout: [],
    };
  },

  computed: {
    ...mapGetters(['staging']),
  },
  components: {
    'image-modal': ImageModal,
  },

  watch: {
    'staging.items'(newValue) {
      if (newValue.length === 0) {
        let index = this.$parent.steps.completed.indexOf(3 - 1);
        if (index > -1) {
          this.$parent.steps.completed.splice(index, 1);
        }

        this.$parent.steps.current = 2;
      }
    },
  },
  created() {
    this.setItemData();
    this.retievePrefectures();
    this.setHouseLayout();
  },
  mounted() {
    this.getAllRoomType();
    this.getAllFurnitures();
    this.getAllAdditionalOptions();
    this.getStyles();
  },
  methods: {
    formatPrice,
    getAllRoomType() {
      let _this = this;
      _this.all_roomTypes.loader = true;
      cus_stagingService_GetRooms({ token: _this.staging.accessToken })
        .then((response) => {
          let data = response.data;

          let roomTypeOptions = [];
          data.forEach((el) => {
            roomTypeOptions.push({
              value: el.id,
              text: el.name,
            });
          });
          _this.all_roomTypes.options = roomTypeOptions;
          _this.all_roomTypes.loader = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStyles() {
      cus_stagingService_GetOfficeFunishingStyle({
        token: localStorage.getItem('_accessToken')
      })
        .then((response) => {
          this.styleList = response.data
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openModal(imgUrl) {
      this.modalImageUrl = imgUrl;
      this.isOpenModal = true;
    },
    closeModal(){
      this.isOpenModal = false;
    },
    checkOptional(isOptional, val) {
      let checkList = document.getElementsByName('furnitures');
      if (isOptional == 1) {
        for (let i = 0; i < checkList.length; i++) {
          if (checkList[i].value != val) {
            checkList[i].checked = false;
          }
        }
      } else {
        for (let i = 0; i < checkList.length; i++) {
          for (let j = 0; j < this.optionalValue.length; j++) {
            if (this.optionalValue[j] == checkList[i].value ) {
              checkList[i].checked = false;
            }
          }
        }
      }
    },
    attention() {
      this.$bvModal.show('step3-attention');
    },
    getAllFurnitures() {
      let _this = this;
      _this.all_furnitures.loader = true;
      cus_stagingService_GetAllFurnitures({ token: _this.staging.accessToken })
        .then((response) => {
          let data = response.data;

          let furnituresOptions = data.map((el) => {
            if (el.is_optional == 1) {
              _this.optionalValue.push(el.id);
            }
            return {
              text: el.name,
              value: el.id,
              room_area_id: el.room_area_id,
              is_optional: el.is_optional
            };
          });
          _this.all_furnitures.options = furnituresOptions;
          _this.all_furnitures.loader = false;

          _this.setFurnitureData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllAdditionalOptions() {
      let _this = this;
      _this.all_additionalOptions.loader = true;
      cus_stagingService_GetAditionalOptions({
        token: _this.staging.accessToken,
      })
        .then((response) => {
          let data = response.data;
          let additionalOptions = data.map((el) => {
            return {
              text: el.name,
              value: el.id,
              price: el.charge,
            };
          });
          _this.all_additionalOptions.options = additionalOptions;
          _this.all_additionalOptions.loader = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setItemData() {
      let _this = this;

      _this.staging.items.forEach((el) => {
        _this.drawingCount[el.id] = el.drawing_count ? el.drawing_count : 0;
        //set each item room type values
        _this.roomType.push({
          itemID: el.id,
          value: el.room_area_id ? el.room_area_id : null,
        });

        //set each item furniture values
        let funituresValue = el.furnitures.map((_el) => {
          return _el.furniture_id;
        });
        _this.furnitures.push({
          itemID: el.id,
          value: funituresValue,
          options: [],
          loader: true,
        });

        //set each item options
        let setAdditionalOptionsValue = el.additional_options.map((_el) => {
          return _el.additional_option_id;
        });
        _this.additionalOptions.push({
          itemID: el.id,
          value: setAdditionalOptionsValue,
        });

        // set remarks
        _this.remarks.push({
          itemID: el.id,
          value: el.remarks ? el.remarks : null,
        });

        // set property
        _this.property_name.push({
          itemID: el.id,
          value: el.property_name ? el.property_name : null,
        });

        _this.disp_list.push({
          itemID: el.id,
          value: el.disp_list ? el.disp_list : 0,
        });

        _this.selectPrefectures.push({
          itemID: el.id,
          value: el.prefecture_id ? el.prefecture_id : null,
        });

        _this.selectHouseLayout.push({
          itemID: el.id,
          value: el.house_layout_id ? el.properthouse_layout_idy_name : null,
        });
      });
      this.$forceUpdate();
    },
    changeType(selected, item_id, i) {
      //set furniture options base on room type
      let furniture_itm = this.all_furnitures.options.find(
        (item) => item.room_area_id === selected
      );
      this.filterFurnitures(furniture_itm.room_area_id, item_id);

      //reset selected values on change
      this.furnitures[i].value = [];
    },
    setFurnitureData() {
      let _this = this;
      _this.staging.items.forEach((el, i) => {
        _this.filterFurnitures(el.room_area_id, el.id);
      });
    },
    filterFurnitures(type, item_id) {
      let _this = this;
      let filtered_furnitures = _this.all_furnitures.options.filter(
        (e) => e.room_area_id === type
      );

      const index = _this.furnitures.findIndex((object) => {
        return object.itemID === item_id;
      });
      //set furniture options base on room type
      _this.furnitures[index].options = filtered_furnitures;
      _this.furnitures[index].loader = false;
    },
    setItemSelectStatus(e) {
      let itemListCheckBox = document.querySelectorAll(
        '#item-list .custom-control-input'
      );
      if (e) {
        this.itemsToBeDeleted = this.staging.items.map((_) => _.id);

        itemListCheckBox.forEach((el) => {
          el.setAttribute('checked', true);
        });
      } else {
        this.itemsToBeDeleted = [];

        itemListCheckBox.forEach((el) => {
          el.removeAttribute('checked');
        });
      }
    },

    setRemovePhotoModal() {
      this.formMessage = null;

      if (this.itemsToBeDeleted.length > 0) {
        this.$bvModal.show('step3-remove-photo');
      } else {
        this.formMessage = 'Please select photos to be deleted.';
      }
    },

    setRemovePhoto() {
      let _this = this;

      _this.$bvModal.hide('step3-remove-photo');

      _this.$store.state.modalLoaderMessage = '削除中';
      _this.$bvModal.show('modal-loader');

      cus_stagingService_DeleteSRItems({
        item_ids: _this.itemsToBeDeleted,
        token: _this.staging.accessToken,
      })
        .then(() => {
          _this.$store.state.staging.items = _this.staging.items.filter(
            (el) => {
              if (!_this.itemsToBeDeleted.includes(el.id)) {
                return el;
              }
            }
          );

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        })
        .catch((error) => {
          console.log(error);

          _this.$bvToast.toast(
            'アップロード中にエラーが起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        });
    },
    async updateItemData() {
      let _this = this;
      _this.formMessage = null;

      _this.$store.state.modalLoaderMessage = '料金を計算中';
      _this.$bvModal.show('modal-loader');

      let items = _this.roomType.map((roomType) => {
        let { itemID } = roomType;
        let furniture = _this.furnitures.find((_) => _.itemID === itemID);
        let additionalOptions = _this.additionalOptions.find(
          (_) => _.itemID === itemID
        );
        let remarks = _this.remarks.find((_) => _.itemID === itemID);
        let disp_list = _this.disp_list.find((_) => _.itemID === itemID);
        let selectPrefectures = _this.selectPrefectures.find((_) => _.itemID === itemID);
        let selectHouseLayout = _this.selectHouseLayout.find((_) => _.itemID === itemID);
        let property_name = _this.property_name.find((_) => _.itemID === itemID);

        return {
          item_id: itemID,
          room_area_id: roomType.value,
          furnitures: furniture.value,
          additional_options: additionalOptions.value,
          remarks: remarks.value,
          disp_list: disp_list.value[0],
          prefectures: selectPrefectures.value,
          house_layout: selectHouseLayout.value,
          property_name: property_name.value,
        };
      });

      let validationErrors = [];
      items.forEach((el, i) => {
        let itemNumber = i + 1;

        if (el.room_area_id === null) {
          validationErrors.push(itemNumber);
        }
      });

      if (validationErrors.length === 0) {
        await cus_stagingService_UpdateSRUpdateItems({
          items: items,
          token: _this.staging.accessToken,
        })
          .then((response) => {
            cus_stagingService_GetAllSRItems({
              id: _this.staging.info.id,
              paginated: 0,
              page_size: 0,
              page: 0,
              token: _this.staging.accessToken,
            })
              .then((_response) => {
                let stagingItems = _response.data.result;

                _this.$store.state.staging.items = stagingItems;

                this.$parent.steps.completed.push(3);
                this.$parent.steps.current = 4;

                setTimeout(() => {
                  _this.$bvModal.hide('modal-loader');
                  setTimeout(() => {
                    _this.$parent.scrollTop();
                  }, 250);
                }, 500);
              })
              .catch((_error) => {
                console.log(_error);

                localStorage.removeItem('_stagingRequestID');

                setTimeout(() => {
                  _this.$bvModal.hide('modal-loader');
                }, 500);
              });
          })
          .catch((error) => {
            console.log(error);

            _this.formMessage =
              '依頼の更新中にエラーが起こりました。再度お試しください。';

            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
          });
      } else {
        _this.formMessage = `お部屋のタイプが必要です。 ${validationErrors.join(
          ', '
        )}.`;

        setTimeout(() => {
          _this.$bvModal.hide('modal-loader');
          _this.$parent.scrollTop();
        }, 500);
      }
    },
    setAddPdf(count, id) {
        if (count < 10) {
        let idName = 'drawing-uploader_' + id;
        let fileUploaders = document.querySelector('#' + idName);

        fileUploaders.value = null;
        fileUploaders.click();
      } else {
        this.formMessage =
          '<li>Maximum of 20 images only. Please remove image to add a new one.</li>';
      }
    },
    delPdf(count, id) {
      if (count > 0) {

        cus_stagingService_DeleteDrawing({
        item_id: id,
        sr_id: this.staging.info.id,
        token: this.staging.accessToken,
      })
        .then(() => {
          this.drawingCount[id] = 0;
          this.$forceUpdate();
          setTimeout(() => {
            this.$bvModal.hide('modal-loader');
          }, 500);
        })
        .catch((error) => {
          console.log(error);

          this.$bvToast.toast(
            '削除中にエラーが起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );

          setTimeout(() => {
            this.$bvModal.hide('modal-loader');
          }, 500);
        });
      }
    },
    validatePdfs(id, e) {
      let _this = this;

      _this.formMessage = null;

      let files = e.target.files;
      let filesErrorMessage = [];

      _this.uploadCount = files.length;

      if (files.length > 0) {
        if (files.length <= 20) {
          for (let i = 0; i < files.length; i++) {
            
            if (
              files[i].type === 'image/jpeg' ||
              files[i].type === 'image/png' ||
              files[i].type === 'application/pdf'
            ) {
              if (files[i].size <= 10000000) {
                console.log('Processing ...');
              } else {
                filesErrorMessage.push(
                  `ファイル名 (${files[i].name}) - 画像は 10 MB 未満である必要があります。`
                );

                _this.$parent.steps.proceedDisabled = false;
                _this.uploadCount = 0;
              }
            } else {
              filesErrorMessage.push(
                `<li>File Name (${files[i].name}) - Please upload a file type of JPEG, JPG or PNG or PDF.</li>`
              );

              _this.uploadCount = 0;
            }
          }
          
          setTimeout(() => {
            _this.validatePdfsProceed(_this, files, filesErrorMessage, id);
          }, 1000);
        } else { 
          _this.uploadCount = 0;
        }
      }
    },
    validatePdfsProceed(_this, files, filesErrorMessage, item_id) {
      if (filesErrorMessage.length === 0) {
        for (let i = 0; i < files.length; i++) {
          _this.loaderItems.push('item-' + i);
          _this.drawingNumber.push(_this.loaderItems.length + i)
          let formData = new FormData();
          formData.append('sr_id', _this.staging.info.id);
          formData.append('item_id', item_id);

          if(files[i].type === 'image/jpeg' || files[i].type === 'image/png' ) {
            formData.append('img', files[i]);
          } else if(files[i].type === 'application/pdf') {
            formData.append('pdf', files[i]);
          }
          formData.append('index', _this.drawingNumber.length);

          cus_stagingService_UpdateDrawing(
            {
              token: _this.staging.accessToken,
            },
            formData
          )
            .then((response) => {
              if (response.data.result) {
                _this.drawing.push(response.data.result);
                this.drawingFileName = response.data.result
                this.drawingCount[item_id] = this.drawingCount[item_id] + 1;
                this.$forceUpdate();
              }
            })
            .catch((error) => {
              _this.uploadCount = 0;
              _this.formMessage = error;
              console.log(error);
            });
        }
      } else {
        _this.formMessage = filesErrorMessage.join('');
        _this.uploadCount = 0;
      }
    },
    retievePrefectures() {
      let _this = this;

      _this.prefectures.loader = true;

      let accessToken = localStorage.getItem('_accessToken');

      globalService_GetAllPrefectures({ token: accessToken })
        .then((response) => {
          let data = response.data;

          let prefectures = [];

          data.forEach((el) => {
            prefectures.push({
              value: el.id,
              text: el.name,
            });
          });

          _this.prefectures.options = prefectures;

          _this.prefectures.loader = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setHouseLayout() {
      let accessToken = localStorage.getItem('_accessToken');
      cus_stagingService_GetHouseLayout({ token: accessToken })
        .then((response) => {
          let data = response.data;

          let houseLayout = [];

          data.forEach((el) => {
            houseLayout.push({
              value: el.id,
              text: el.name,
            });
          });

          this.houseLayout.options = houseLayout;

          this.houseLayout.loader = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
.room-type-select {
  background-color: #ffffff !important;
}

#step-3-content .photo-details-item {
  margin: 40px 0 0 0;
  box-shadow: 0px 0px 25px #00000029;
  border-radius: 15px;
  background: #f5f5f5;
}

#step-3-content .photo-details-item .photo {
  padding: 0;
  height: 100%;
  width: 100%;
  max-height: 345px;
}

@media only screen and (max-width: 1440px) {
  #step-3-content .photo-details-item .photo {
    /* padding: 14px; */
  }
}

@media only screen and (min-width: 1200px) {
  .layout {
    max-width:50%;
  }
}

@media only screen and (min-width: 1200px) {
  .comment {
    width:300px;
  }
}
@media only screen and (min-width: 767px) {
  .comment {
    max-width:100%;
  }
}

@media only screen and (max-width: 767px) {
  #step-3-content .photo-details-item .photo {
    padding: 14px;
  }
}

#step-3-content .photo-details-item .photo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

@media only screen and (max-width: 1440px) {
  #step-3-content .photo-details-item .photo img {
    object-fit: cover;
    border-radius: 15px;
    max-height: 245px;
  }
}

@media only screen and (max-width: 1199px) {
  #step-3-content .photo-details-item .photo img {
    object-fit: cover;
    border-radius: 15px;
  }
}

#step-3-content .photo-details-item .remove-phohoto-dsk {
  display: block;
  float: right;
  margin-top: 20px;
}

#step-3-content .photo-details-item .remove-phohoto-mbl {
  display: none;
}

@media only screen and (max-width: 1199px) {
  #step-3-content .photo-details-item .remove-phohoto-dsk {
    display: none;
  }

  #step-3-content .photo-details-item .remove-phohoto-mbl {
    display: block;
    position: absolute;
    top: 20px;
    right: 0;
  }
}

#step-3-content .photo-details-item .funiture-select {
  margin-top: 92px;
}

.option-select {
  margin-top: 50px;/*106px*/
}

@media only screen and (max-width: 1199px) {
  #step-3-content .photo-details-item .funiture-select {
    margin-top: 20px;
  }

  .option-select {
    margin-top: 20px;
  }
}
.btn-dt {
  font-weight: bold;
  font-size: 11px;
  border:none;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: -30px;
}
.main-container {
  margin-top: 9px;
  display: grid;

}
.list {
  display: flex;
  width:100%;
  flex-wrap: wrap;
  margin-right: -23px;
  margin-left: 9px;
  margin-bottom: 15px;
}
.form-input {
  display: block;
  height: 100% !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.form-area {
  display: block; 
  height: calc(1.5em + 5.75rem + 10px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.drawing-area {
  border:1px solid #055ab8;
  background: #fff;
  border-radius:6px;
  padding:7px;
  margin-left: 3px;
}
.select-furniture {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

}
</style>
